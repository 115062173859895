@import "src/_app/styles/mixins.scss";
.banner {
  position: relative;
  height: auto;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url('/icons/main/traiding-banner-bg.svg'), #0a0a0a;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  border: 1px solid #3c3c3c;
  color: var(--white);
  overflow: hidden;

  &::before,
  &::after {
    position: absolute;
    background: #c7c7c7;
    border-radius: 200px;
    filter: blur(300px);
    transform: translate3d(0, 0, 0);
    content: ' ';
  }

  &::before {
    width: 229px;
    height: 229px;
    top: -53px;
    left: -117px;
    opacity: 0.8;
  }

  &::after {
    width: 352px;
    height: 352px;
    top: -176px;
    left: 70%;
    opacity: 0.2;

    @include respond(laptop) {
      right: 0;
    }
  }

  &_text_top {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  @include respond(laptopL) {
    height: auto;
    padding: 24px;
    flex-flow: column;
    align-items: start;

    &_text_top {
      h6 {
        font-size: 20px;
        line-height: 28px;
      }
    }

    h2 {
      font-size: 32px;
      line-height: 42px;
    }
  }

  @include respond(mobileL) {
    padding: 16px;

    &_background {
      height: 200px;

      svg {
        width: calc(100vw - 48px);
      }
    }
  }

  h2 {
    margin: 0;
    width: max-content;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;

    @include respond(tabletL) {
      width: auto;
    }

    @include respond(mobileL) {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
  }

  h6 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;

    @include respond(mobileL) {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &__link {
    max-width: max-content;
    height: auto;
    padding: 12px 20px;
    display: block;
    border-radius: 10px;
    background-color: var(--white);
    color: var(--black);
    z-index: 3;

    @include tButton_2;

    @include respond(laptopL) {
      margin-top: 24px;
    }

    @include respond(mobileL) {
      padding: 8px 16px;
    }
  }
}
